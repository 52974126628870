export default function useEventBusListener(eventName, callback) {
    const { $eventbus } = useNuxtApp();

    onMounted(() => {
        $eventbus.on(eventName, callback);
    });

    onBeforeUnmount(() => {
        $eventbus.off(eventName, callback);
    });
}
