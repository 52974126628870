<script setup>
const props = defineProps({
    show: {
        type: Boolean,
    },
    size: String,
    title: String,
    typeClass: String,
    contentBodyClass: String,
    name: {
        type: String,
        // required: true,
    },
    closeable: {
        type: Boolean,
        default: true,
    },
    closeOnBackdrop: {
        type: Boolean,
        default: true,
    },
});

const content = ref();

const emit = defineEmits(['close', 'open', 'update:show']);

// const disabled = computed(() => !props.show);

const bodyClass = computed(() => {
    return props.show
        ? 'overflow-y-hidden font-sans antialiased'
        : 'font-sans antialiased';
});

useHead({
    bodyAttrs: {
        class: computed(() => bodyClass.value),
    },
});

/**
 * Close Modal.
 */
const close = () => {
    emit('close');
    emit('update:show', false);
};

/**
 * Open Modal.
 */
const open = () => {
    emit('open');
    emit('update:show', true);
};

const switchModal = (modalName, event) => {
    if (modalName === props.name) {
        emit('update:show', true);
        emit('open', event);
        return;
    }

    if (props.show) {
        close();
    }
};

useEventBusListener('switchModal', switchModal);

if (props.closeable) {
    useClickOutside(content, () => {
        /*  if(props.show) {
            close();
         } */
    });
}

const keydownEsc = () => {
    if (props.show && props.closeable) {
        close();
    }
};

const slots = useSlots();
const hasSlot = (name) => {
    return !!slots[name];
};

defineExpose({
    close,
    open,
});
</script>

<template>
    <div>
        <teleport to="body">
            <div
                v-if="props.show"
                :data-modal="name"
                class="fixed top-0 left-0 w-full h-full z-50"
            >
                <div
                    class="modal-backdrop"
                    @click="closeable && closeOnBackdrop ? close() : ''"
                />
                <div
                    ref="content"
                    class="modal d-block"
                    :class="[size, typeClass]"
                    tabindex="-1"
                    @keydown.esc.prevent="keydownEsc"
                >
                    <div ref="trapRef" class="modal-dialog">
                        <div class="modal-content">
                            <slot
                                name="header-container"
                                :close="close"
                                :closeable="closeable"
                            >
                                <div
                                    v-if="hasSlot('header')"
                                    class="modal-header"
                                >
                                    <slot name="header">
                                        <h5
                                            v-if="props.title"
                                            class="modal-title"
                                            v-text="props.title"
                                        />
                                    </slot>
                                    <CommonModalClose
                                        v-if="closeable"
                                        @click="close"
                                    />
                                </div>
                            </slot>
                            <div class="modal-body" :class="contentBodyClass">
                                <slot />
                            </div>
                            <div
                                v-if="hasSlot('footer')"
                                class="modal-footer bordered"
                            >
                                <slot name="footer">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                    >
                                        {{ $t('admin.account.save_changes') }}
                                    </button>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>
